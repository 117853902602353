import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import PrivacyContent from '../components/privacy/PrivacyContent'

interface IPrivacyPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        by: string
      }
    }
  }
}

export const query = graphql`
query PrivacyPageQuery {
  site {
    siteMetadata {
      title
      by
    }
  }
}`

export default class PrivacyPage extends React.Component<IPrivacyPageProps, {}> {
  render() {
    const { data: { site: { siteMetadata: meta } } } = this.props
    return (
      <Layout meta={{description: 'GDTecnologie rispetta la tua privacy'}}>
        <Helmet>
          <title>Privacy & Cookie Policy | {meta.title} di {meta.by}</title>
        </Helmet>

        <div className="section">
          <div className="columns is-centered">
            <div className="column is-half">
              <PrivacyContent />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

